<template>
  <b-card v-if="labData">
    <b-row align-h="between" class="mx-0">
      <div class="d-flex">
        <feather-icon icon="FileTextIcon" size="19" />
        <h4 class="mb-0 ml-50">
          {{ $t('admin.labs.form.generalInformation') }}
        </h4>
      </div>
    </b-row>

    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        class="mt-1"
        @submit.prevent="
          handleSubmit(
            submit(labData, giveMeasurements, removeMeasurements, qtyMeas),
          )
        "
      >
        <b-row>
          <b-col v-if="profile" cols="12" md="2" lg="2">
            <input
              title="logo"
              type="file"
              accept="image/jpeg, image/png"
              ref="refLogoInput"
              style="display: none"
              @change="uploadLogo($event)"
            />

            <b-row align-h="center" class="m-0 p-0">
              <b-avatar
                rounded
                size="120px"
                variant="light-primary"
                :src="logoUrl"
                :text="labData.nameFantasy[0] || labData.nameRegistered[0]"
              />
            </b-row>

            <b-row align-h="center" class="m-0 p-0 mt-1">
              <b-button
                id="upload"
                size="sm"
                variant="outline-primary"
                @click="refLogoInput.click()"
              >
                <feather-icon icon="UploadIcon" />
              </b-button>
              <b-tooltip triggers="hover" placement="bottom" target="upload">
                <b>Upload</b>
              </b-tooltip>

              <b-button
                v-if="logoUrl"
                id="remove"
                class="ml-1"
                size="sm"
                variant="outline-danger"
                @click="removeLogo()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-tooltip triggers="hover" placement="bottom" target="remove">
                <b>Delete</b>
              </b-tooltip>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            :md="profile ? '10' : '12'"
            :lg="profile ? '10' : '12'"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('admin.labs.form.fantasyName')"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.labs.form.fantasyName')"
                label-for="nameFantasy"
              >
                <b-form-input
                  v-model="labData.nameFantasy"
                  class="form-control"
                  :placeholder="$t('admin.labs.form.fantasyName')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('admin.labs.form.registeredName')"
              rules="required"
            >
              <b-form-group
                :label="$t('admin.labs.form.registeredName')"
                label-for="nameRegistered"
              >
                <b-form-input
                  v-model="labData.nameRegistered"
                  class="form-control"
                  :placeholder="$t('admin.labs.form.registeredName')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              :name="$t('opticForm.sectionField8')"
              rules="required"
            >
              <b-form-group
                :label="$t('opticForm.sectionField8')"
                label-for="CNPJ"
              >
                <cleave
                  v-model="labData.cnpj"
                  class="form-control"
                  :options="cnpjDelimiter"
                  :placeholder="$t('opticForm.sectionField8')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subscription Plan -->
            <div v-if="isSuperAdmin">
              <!-- Choose between Stripe and Lensxpert plans -->
              <b-form-group
                :label="$t('opticForm.sectionField9')"
                v-slot="{ ariaDescribedby }"
              
              >
                <b-row class="mx-0">
                  <b-form-radio
                    v-model="planType"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="external"
                    >External</b-form-radio
                  >
                  <b-form-radio
                    class="ml-1"
                    v-if="$route.params.id == 'new'"
                    v-model="planType"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="stripe"
                    >Stripe</b-form-radio
                  >
                </b-row>
              </b-form-group>
              <div v-if="planType === 'stripe'">
                <b-row align-v="center" align-h="between">
                  <b-col cols="10">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('admin.labs.form.subscriptionPlan')"
                    >
                      <b-form-group
                        :label="$t('admin.labs.form.subscriptionPlan')"
                        label-for="subscription"
                      >
                        <v-select
                          v-model="subscription"
                          label="description"
                          :placeholder="$t('admin.labs.form.subscriptionPlan')"
                          :options="plans"
                          :clearable="false"
                        />
                      </b-form-group>

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <!-- Is trial Plan -->
                  <b-col v-if="subscription">
                    <b-row align-h="end" class="mr-1">
                      <span class="mr-1">{{
                        $t('admin.labs.softwarePartnerForm.trialPlan')
                      }}</span>
                      <b-form-checkbox v-model="isTrialPlan" />
                    </b-row>
                  </b-col>
                </b-row>
                <validation-provider
                  v-if="isTrialPlan"
                  #default="validationContext"
                  rules="required|integer|positive"
                  :name="$t('admin.labs.form.trialDays')"
                >
                  <b-form-group
                    :label="$t('admin.labs.form.trialDays')"
                    label-for="trial-days"
                  >
                    <b-form-input
                      v-model="trialDays"
                      id="trial-days"
                      :placeholder="$t('admin.labs.form.trialDays')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>

              <div v-if="planType === 'external'">
                <validation-provider
                  #default="validationContext"
                  :name="$t('admin.labs.form.subscriptionPlan')"
                >
                  <b-form-group
                    :label="$t('admin.labs.form.subscriptionPlan')"
                    label-for="subscription"
                  >
                    <v-select
                      v-model="subscription"
                      label="name"
                      :placeholder="$t('admin.labs.form.subscriptionPlan')"
                      :options="externalPlans"
                      :clearable="false"
                    />
                  </b-form-group>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>

            <validation-provider
              v-if="!profile && !isFromRequestAccessForm"
              #default="validationContext"
              name="Laboratory admin"
              rules="required|email"
            >
              <b-form-group
                :label="$t('admin.labs.form.labAdmin')"
                label-for="adminEmail"
              >
                <b-form-input
                  v-model="labData.userEmail"
                  id="adminEmail"
                  :placeholder="$t('admin.labs.form.labAdmin')"
                  :disabled="$route.params.id !== 'new'"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Address -->
        <AddressForm :address="labData.address" />
        <!-- Header: Address -->

        <!-- allowedMeasurements -->
        <b-col
          cols="12"
          v-if="!isFromRequestAccessForm && !profile && !isSuperAdmin"
        >
          <b-row align-v="center">
            <span class="mr-1"
              >Esta entidade usou
              <strong>{{
                `${labData.qtyMeasurements}/${labData.allowedMeasurements || 0}`
              }}</strong>
              medidas</span
            >
            <b-button
              class="mr-1"
              :variant="giveMeasurements ? 'secondary' : 'outline-secondary'"
              @click="
                showAllowedMeasurementTextField = true
                giveMeasurements = true
                removeMeasurements = false
              "
            >
              Adicionar medições
            </b-button>
            <b-button
              class="mr-1"
              :variant="removeMeasurements ? 'warning' : 'outline-warning'"
              @click="
                showAllowedMeasurementTextField = true
                removeMeasurements = true
                giveMeasurements = false
              "
            >
              Remover medições
            </b-button>
          </b-row>
          <b-row align-v="center" v-if="showAllowedMeasurementTextField">
            <validation-provider
              #default="validationContext"
              name="Quantity"
              :rules="`required|${
                giveMeasurements
                  ? `between:0,${userAdminDoc.availableMeasurements}`
                  : removeMeasurements
                  ? `between:0,${
                      (labData.allowedMeasurements || 0) -
                      labData.qtyMeasurements
                    } `
                  : ''
              }`"
            >
              <b-form-group label="Quantity" label-for="quantity">
                <b-form-input
                  v-model="qtyMeas"
                  class="form-control"
                  placeholder="0"
                  :state="getValidationState(validationContext)"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div v-if="userAdminDoc">
              <strong class="ml-1" v-if="giveMeasurements">
                {{ `${userAdminDoc.availableMeasurements} Disponíveis` }}
              </strong>
              <strong class="ml-1" v-if="removeMeasurements">
                <span v-if="labData">
                  {{
                    `${
                      labData.allowedMeasurements - labData.qtyMeasurements
                    } Disponíveis para retirada`
                  }}
                </span>
              </strong>
            </div>
          </b-row>
        </b-col>

        <b-row class="mt-2 pr-1" align-h="end" v-if="!isFromRequestAccessForm">
          <b-button
            v-if="!profile"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :to="{ name: 'labs' }"
          >
            {{ $t('admin.labs.form.cancel') }}
          </b-button>
          <b-button
            type="submit"
            :variant="invalid ? 'secondary' : 'primary'"
            :disabled="loading || invalid"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            <b-spinner v-if="loading" small></b-spinner>

            <span v-else>{{ $t('admin.labs.form.save') }}</span>
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTooltip,
  BFormInvalidFeedback,
  BSpinner,
  BFormRadio,
} from 'bootstrap-vue'

import { onUnmounted } from 'vue-demi'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import { computed } from '@vue/composition-api'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, email, positive } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import store from '@/store'
import router from '@/router'

import labStoreModule from './labStoreModule'
import useLabForm from './useLabForm'

import { CreditEntity } from '@/entities/credit.entity'
import ReasonEnum from '@/utils/credits'

import { cnpjDelimiter } from '@/utils/formatters'
import RoleEnum from '@/utils/roles'
import { createStorageUrl, getExtension } from '@/utils/images'

import creditStoreModule from '../credits/creditStoreModule'
import planStoreModule from '@/views/admin/plans/planStoreModule'
import opticStoreModule from '@/views/sections/optics/opticStoreModule'
import LaboratoryEntity from '@/entities/laboratory.entity'
import OpticsList from '@/views/sections/labs/components/OpticsList.vue'
import AddressForm from '@/views/sections/components/AddressForm.vue'
import userStoreModule from '@/views/admin/users/userStoreModule'

export default {
  name: 'LabForm',

  props: {
    profile: Boolean,
    data: {
      type: Object,
      required: false,
    },
    isFromRequestAccessForm: Boolean,
  },

  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTooltip,
    BFormInvalidFeedback,
    BSpinner,
    BFormRadio,
    Cleave,

    vSelect,
    AddressForm,

    ValidationProvider,
    ValidationObserver,

    OpticsList,
  },

  data() {
    return {
      required,
      integer,
      email,
      positive,
      cnpjDelimiter,
      isNew: false,
      viewOptics: false,
      showAllowedMeasurementTextField: false,
      giveMeasurements: false,
      removeMeasurements: false,
      qtyMeas: 0,
    }
  },

  methods: {
    isPlanParent() {
      if (this.user.role === RoleEnum.SELLER) {
        return false
      } else {
        const planParent =
          this.user.adminDocRef.split('/').length > 2 ? false : true

        if (!planParent) {
          return false
        }
        return true
      }
    },
    async getOptics() {
      let path = `laboratories/${this.$route.params.id}`
      if (
        store.state.auth.user.adminDocRef &&
        store.state.auth.user.adminDocRef.length > 0
      ) {
        path = store.state.auth.user.adminDocRef
      }
      await this.$store.dispatch('optic/fetchOptics', path)
      this.viewOptics = true
    },
  },

  watch: {
    subscription(val) {
      if (val && val.id === undefined) {
        if (this.planType === 'external') {
          this.subscription = this.externalPlans.find(
            (el) => el.id === this.subscription,
          )
        }
      }
    },
    labData: {
      deep: true,
      handler(val) {
        if (this.isFromRequestAccessForm) {
          this.$emit('update-data', val)

          if (!this.refFormObserver) {
            return
          }

          setTimeout(() => {
            this.$emit('update-valid', this.refFormObserver._data.flags.valid)
          }, 100)
        }
      },
    },
  },

  computed: {
    userAdminDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    isSuperAdmin() {
      return this.$store.state.auth.user.role === RoleEnum.SUPER_ADMIN
        ? true
        : false
    },
    plans() {
      const labPlan = this.$store.state.plan.plans.find(
        (el) => el.metadata && el.metadata.type === 'laboratory',
      )
      return labPlan?.prices.filter((el) => el.active == true) ?? []
    },
    optics() {
      return this.$store.state.optic.optics
    },
    user() {
      return this.$store.state.auth.user
    },
    externalPlans() {
      return this.$store.state.plan.plans_lensxpert || []
    },
  },

  async created() {
    this.isNew = this.$route.params.id === 'new' ? true : false
    // if (!this.isPlanParent()) {
    //   const parent = this.user.adminDocRef

    //   const parentBaseUrl = [parent.split('/')[0], parent.split('/')[1]]
    //   const parentDocument = await new Controller().getById(parentBaseUrl)
    //   const logoString = parentDocument.data().logo

    //   this.logoUrl = this.createStorageUrl(logoString)
    // }
  },

  setup(props) {
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const PLAN_APP_STORE_MODULE_NAME = 'plan'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const CREDIT_APP_STORE_MODULE_NAME = 'credit'
    const USER_APP_STORE_MODULE_NAME = 'user'

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    if (!store.hasModule(CREDIT_APP_STORE_MODULE_NAME)) {
      store.registerModule(CREDIT_APP_STORE_MODULE_NAME, creditStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)

      store.hasModule(CREDIT_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(CREDIT_APP_STORE_MODULE_NAME)

      store.hasModule(USER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    if (!props.isFromRequestAccessForm) {
      store.dispatch('plan/fetchPlans')
      store.dispatch('plan/fetchPlansLensxpert')
    }

    const {
      labData,
      resetData,
      loadData,
      onSubmit,
      subscription,
      isTrialPlan,
      trialDays,
      uploadLogo,
      removeLogo,
      logo,
      logoUrl,
      refLogoInput,
      planType,
    } = useLabForm()

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData)

    const id = router.currentRoute.params.id
    const parent = router.currentRoute.query.p

    if (!props.isFromRequestAccessForm) {
      if (id !== 'new' && !props.profile) {
        let path = `laboratories/${id}`
        if (
          store.state.auth.user.adminDocRef &&
          store.state.auth.user.adminDocRef.length > 0
        )
          path = `${store.state.auth.user.adminDocRef}/laboratories/${id}`
        else if (store.state.auth.user.role == RoleEnum.SUPER_ADMIN)
          path = `${parent}/laboratories/${id}`

        loadData(path)
      }

      if (props.profile) {
        labData.value = new LaboratoryEntity(props.data)

        if (labData.value.logo) {
          logoUrl.value = createStorageUrl(labData.value.logo)
        }
      }
    }

    const loading = computed({
      get: () => store.state['laboratory'].loading,
    })

    /**
     * Submits the form data.
     *
     * @param {LaboratoryEntity} data the data to be submitted.
     */
    const submit = (data, giveMeasurements, removeMeasurements, qtyMeas) => {
      const user = store.state.auth.user
      const ownerRef = user.adminDocRef || user.sellerDocRef

      const credit = new CreditEntity({
        ownerRef,
        reason: ReasonEnum.ASSIGNMENT,
      })

      const diff = giveMeasurements ? parseInt(qtyMeas || 0) : -parseInt(qtyMeas || 0)

      const labCredit = new CreditEntity({
        reason: diff < 0 ? ReasonEnum.REMOVED : ReasonEnum.RECEIVED,
        difference: diff,
        remaining: data.availableMeasurements + diff,
      })

      if (!data.allowedMeasurements) {
        data.allowedMeasurements = 0
      }

      if (!data.availableMeasurements) {
        data.availableMeasurements = 0
      }

      const userAdminDoc = store.state.auth.userAdminDoc

      if (giveMeasurements) {
        data.allowedMeasurements =
          parseInt(data.allowedMeasurements || 0) + parseInt(qtyMeas || 0)
        data.availableMeasurements += parseInt(qtyMeas || 0)

        credit.difference = -parseInt(qtyMeas || 0)
        credit.remaining = !userAdminDoc
          ? null
          : parseInt(userAdminDoc.availableMeasurements) - parseInt(qtyMeas || 0)
      } else if (removeMeasurements) {
        data.allowedMeasurements =
          parseInt(data.allowedMeasurements) - parseInt(qtyMeas || 0)
        data.availableMeasurements -= parseInt(qtyMeas || 0)

        credit.difference = parseInt(qtyMeas || 0)
        credit.remaining = !userAdminDoc
          ? null
          : parseInt(userAdminDoc.availableMeasurements) + parseInt(qtyMeas || 0)
      }

      const ext = getExtension(logo.value)

      let logoPath = ''
      if (ext) {
        logoPath = `laboratories/${data.id}/logo.${ext}`
      } else if (logoUrl.value) {
        logoPath = data.logo
      }

      onSubmit(
        new LaboratoryEntity({
          ...data,
          logo: logoPath,
        }),
        props.profile,
        parent,
        credit,
        !diff ? null : labCredit,
      )
    }

    return {
      labData,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      submit,
      subscription,
      isTrialPlan,
      trialDays,
      uploadLogo,
      removeLogo,
      logo,
      logoUrl,
      refLogoInput,
      createStorageUrl,
      planType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__deselect svg {
  fill: #fff;
}

.dark-layout .vs__deselect svg {
  fill: #7367f0;
}

.b-avatar .b-avatar-img img {
  object-fit: contain;
}
</style>
