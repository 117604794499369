import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import store from '@/store'
import router from '@/router'

import LaboratoryEntity from '@/entities/laboratory.entity'
import AuthLinkEntity from '@/entities/auth-link.entity'
import AddressModel from '@/models/address.model'
import RoleEnum from '@/utils/roles'
import { createStorageUrl, readUrl } from '@/utils/images'
import AuthController from '@/controllers/auth.controller'
import { CreditEntity } from '@/entities/credit.entity'
import PlanController from '@/controllers/plan.controller'
import UserController from '@/controllers/user.controller'

export default function useLabForm() {
  const toast = useToast()

  const blankData = new LaboratoryEntity({
    nameFantasy: '',
    nameRegistered: '',
    logo: '',
    adminId: '',
    userEmail: '',
    address: new AddressModel({
      line1: '',
      line2: '',
      country: '',
      number: null,
      zipCode: '',
    }),
    status: 'pending',
  })

  const labData = ref(new LaboratoryEntity(blankData))

  const subscription = ref(null)
  const isTrialPlan = ref(false)
  const trialDays = ref(null)
  const optics = ref([])

  const refLogoInput = ref(null)
  const logoUrl = ref('')
  const planType = ref(null)

  /**
   * @type {import('@vue/composition-api').Ref<File>}
   */
  const logo = ref(null)

  /**
   * Loads a laboratory based on the given id.
   *
   * @param {string} id the laboratory id.
   */
  const loadData = async (id) => {
    try {
      labData.value = await store.dispatch('laboratory/fetchOneLab', id)
      optics.value = labData.value.optics

      if (labData.value.logo) {
        logoUrl.value = createStorageUrl(labData.value.logo)
      }

      if (labData.value.adminId.length > 0) {
        let user = await store.dispatch(
          'user/fetchOneUser',
          labData.value.adminId,
        )

        if (user.plan && user.plan.type === 'external') {
          subscription.value = user.plan.id
          planType.value = user.plan.type
        }
      }
    } catch (e) {
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.labForm.failure.fetching'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  /**
   * Resets the laboratory data.
   */
  const resetData = () => {
    labData.value = new LaboratoryEntity(blankData)
  }

  /**
   * Sets the given data into the database.
   *
   * @param {LaboratoryEntity} data the data to be set.
   * @param {boolean} profile whether the laboratory is being edited from the profile page.
   * @param {string} parent the parent document path.
   * @param {CreditEntity} credit the credit to be created.
   * @param {CreditEntity | null} labCredit the lab credit to be created.
   */
  const onSubmit = async (data, profile, parent, credit, labCredit) => {
    const id = profile ? data.id : router.currentRoute.params.id

    let alId

    if (id === 'new') {
      // User validations
      const userExists = await new AuthController().checkIfUserExists(
        data.userEmail,
      )
      const userWasInvited = await new AuthController().checkIfEmailWasInvited(
        data.userEmail,
      )
      if (userExists || userWasInvited) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('firebase.error.emailInUse'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }
    }

    try {
      // Logic for use on profile update only
      if (profile) {
        const path =
          id !== 'new'
            ? `${store.state.auth.user.adminDocRef}`
            : store.state.auth.user.adminDocRef

        await store.dispatch('laboratory/setLaboratory', {
          data,
          id: id !== 'new' ? id : null,
          parent: path,
        })

        if (logo.value) {
          await store.dispatch('laboratory/uploadLogo', {
            path: data.logo,
            file: logo.value,
          })
        } else if (labData.value.logo && !logoUrl.value) {
          await store.dispatch('laboratory/removeLogo', labData.value.logo)
        }

        labData.value = new LaboratoryEntity(data)

        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toast.labForm.save'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        return
      }

      // Save entity
      const path =
        id !== 'new'
          ? parent
            ? `${parent}/laboratories/${id}`
            : `laboratories/${id}`
          : parent

      const result = await store.dispatch('laboratory/setLaboratory', {
        data,
        id: id !== 'new' ? id : null,
        parent: path,
      })

      // Return if edit
      if (id !== 'new') {
        // Update entity external plan
        if (
          planType.value === 'external' &&
          store.state.auth.user.role === RoleEnum.SUPER_ADMIN
        ) {
          // Send email granting access
          const emailData = {
            company: data.nameFantasy,
            toEmail: data.userEmail,
            subject: 'Plano Atualizado!',
            name: 'Lensxpert',
            fromEmail: '',
          }
          await new UserController().sendUpdateExternalPlanEmail(emailData)

          // Update entity
          await new PlanController().subscribeToExternalPlan({
            userId: data.adminId,
            planId: subscription.value.id,
            entityPath: `laboratories/${id}`,
          })
        }

        credit.targetRef = path

        router.push({ name: 'labs' })

        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toast.labForm.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        if (
          store.state.auth.user.role !== RoleEnum.SUPER_ADMIN &&
          credit.difference
        ) {
          store.dispatch('credit/create', credit)

          if (labCredit) {
            labCredit.ownerRef = path

            store.dispatch('credit/create', labCredit)
          }
        }

        return
      } else if (
        store.state.auth.user.role !== RoleEnum.SUPER_ADMIN &&
        credit.difference
      ) {
        const tPath = `${path}/laboratories/${result.id || result.data.id}`
        credit.targetRef = tPath

        store.dispatch('credit/create', credit)

        if (labCredit) {
          labCredit.ownerRef = tPath

          store.dispatch('credit/create', labCredit)
        }
      }

      // Create auth link to invite user to enter the platform
      const authLink = new AuthLinkEntity({
        email: data.userEmail,
        relationPath: result.relationPath,
        role: RoleEnum.LABORATORY_ADMIN,
      })

      // In case user that created is super admin, assign selected plan
      if (planType.value === 'stripe') {
        if (store.state.auth.user.role == RoleEnum.SUPER_ADMIN) {
          authLink.plan = subscription.value.id
          authLink.isTrialPlan = isTrialPlan.value
          authLink.trialDays = trialDays.value
          authLink.planType = 'stripe'
        }
      } else if (planType.value === 'external') {
        if (store.state.auth.user.role == RoleEnum.SUPER_ADMIN) {
          authLink.plan = subscription.value.id
          authLink.isTrialPlan = false
          authLink.trialDays = null
          authLink.planType = 'external'
        }
      } else {
        authLink.plan = null
        authLink.isTrialPlan = false
        authLink.trialDays = null
        authLink.planType = null
      }

      const res = await store.dispatch('auth/createAuthLink', authLink)
      alId = res.id

      await store.dispatch('auth/sendSignInLink', {
        email: data.userEmail,
        authLinkId: alId,
      })

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.labForm.save'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      router.push({ name: 'labs' })
    } catch (e) {
      console.log(e)
      store.dispatch('auth/deleteAuthLink', alId ?? '')

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.labForm.failure.creating'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  /**
   * Uploads the logo as an URL.
   */
  const uploadLogo = async () => {
    const files = refLogoInput.value.files

    if (!files || !files.length) {
      return
    }

    if (!files[0].type.match('image/*')) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.labForm.failure.typeNotSupported'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return
    }

    if (files[0].size > 5000000) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.labForm.failure.sizeNotAllowed'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return
    }

    logo.value = files[0]
    logoUrl.value = await readUrl(files[0])
  }

  /**
   * Removes the logo.
   */
  const removeLogo = () => {
    refLogoInput.value.value = ''
    logo.value = null
    logoUrl.value = ''
  }

  return {
    labData,
    resetData,
    loadData,
    onSubmit,
    subscription,
    optics,
    isTrialPlan,
    trialDays,
    uploadLogo,
    removeLogo,
    logo,
    logoUrl,
    refLogoInput,
    planType,
  }
}
